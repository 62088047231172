import Logger from '@/_helpers/logger';
import config from '@/config';
import { Env } from '@/lib/types';

import { CampaignEventName, KnownUtmSource, TrackCampaignEventFunc } from './types';

const sentCampaignEvents = new Set<CampaignEventName>();

function isAdsCampaignTrackingOn() {
    return config.ENV === Env.Prod;
}

const sendFbPixelEvent: TrackCampaignEventFunc = (eventName, eventData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fbq = (window as any)['fbq'];
    if (!fbq) {
        Logger.warn(
            `Failed to send pixel event "${eventName}" (data: ${JSON.stringify(eventData)})`,
        );
        return;
    }
    sentCampaignEvents.add(eventName);
    return fbq('track', eventName, eventData);
};

const sendGtagEvent: TrackCampaignEventFunc = (eventName, eventData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any)['dataLayer'];
    if (!dataLayer) {
        Logger.warn(
            `Failed to send gtag event "${eventName}" (data: ${JSON.stringify(eventData)})`,
        );
        return;
    }
    sentCampaignEvents.add(eventName);
    dataLayer.push({ event: eventName, ...eventData });
};

export function sendAdsCampaignEventOnce(
    source: KnownUtmSource | undefined,
    eventName: CampaignEventName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventData?: Record<string, any>,
) {
    if (!isAdsCampaignTrackingOn()) {
        return;
    }
    if (sentCampaignEvents.has(eventName)) {
        return;
    }
    if (source === KnownUtmSource.Google) {
        sendGtagEvent(eventName, eventData);
    } else if (source === KnownUtmSource.Facebook) {
        sendFbPixelEvent(eventName, eventData);
    }
}
