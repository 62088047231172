import { DialogContentWithFooter, Link } from '@/components/ui';
import { dayOfWeekOptions } from '@/lib/api/v1';
import {
    DateIsoString,
    DayString,
    SetStateFunc,
    dayStringToIsoString,
    toISOString,
} from '@/lib/base';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';
import { getDayFromDateMap } from '@/strings/common';
import { addMonths, format } from 'date-fns';
import { last } from 'lodash-es';

import GoBackArrow from '../common/GoBackArrow';
import CalendarWeek from './CalendarWeek';
import {
    getCurrentMonthStart,
    getEmptyWeek,
    getMonthAndYear,
    getMonthWeeks,
    yearAndMonthToDate,
} from './utils';

const strings = dialogsStrings.scheduleInterview;

interface Props {
    goToTalkToUs: () => void;
    monthAnchor: DateIsoString;
    setMonthAnchor: SetStateFunc<DateIsoString>;
    availableDays: DayString[];
    onDaySelect: (day: DayString) => void;
    trackGoToNextMonth: (month: string) => void;
    trackGoToPrevMonth: (month: string) => void;
}

export function SelectDateContent({
    monthAnchor,
    setMonthAnchor,
    goToTalkToUs,
    availableDays,
    onDaySelect,
    trackGoToNextMonth,
    trackGoToPrevMonth,
}: Props) {
    const goToNextMonth = () => {
        trackGoToNextMonth(format(new Date(monthAnchor), 'MMMM'));
        setMonthAnchor((dateAnchor) => toISOString(addMonths(new Date(dateAnchor), 1)));
    };
    const goToPrevMonth = () => {
        trackGoToPrevMonth(format(new Date(monthAnchor), 'MMMM'));
        setMonthAnchor((dateAnchor) => toISOString(addMonths(new Date(dateAnchor), -1)));
    };
    const { month, year } = getMonthAndYear(monthAnchor);
    const monthDates = getMonthWeeks({ month, year });

    const lastAvDay = last(availableDays);
    const firstMonth = getMonthAndYear(getCurrentMonthStart());
    const lastMonth = getMonthAndYear(
        lastAvDay ? dayStringToIsoString(lastAvDay) : getCurrentMonthStart(),
    );
    return (
        <DialogContentWithFooter
            footer={
                <div
                    className={cn(
                        'flex justify-center text-12 font-medium whitespace-pre select-none',
                    )}
                >
                    {strings.dateSelection.noGoodMatch.description}{' '}
                    <Link onClick={goToTalkToUs}>
                        {strings.dateSelection.noGoodMatch.link}
                    </Link>
                </div>
            }
        >
            <div className={cn('flex w-full justify-center')}>
                <div className={cn('w-full select-none max-w-[400px]')}>
                    <div
                        className={cn('w-full flex items-center justify-between gap-6')}
                    >
                        {new Date(monthAnchor) > yearAndMonthToDate(firstMonth) ? (
                            <GoBackArrow onClick={goToPrevMonth} />
                        ) : (
                            <div className={cn('w-12')} />
                        )}
                        <div className={cn('text-14 font-semibold')}>
                            {strings.dateSelection.monthTitle.func(
                                new Date(monthAnchor),
                            )}
                        </div>
                        {new Date(monthAnchor) < yearAndMonthToDate(lastMonth) ? (
                            <GoBackArrow
                                className={cn('rotate-180')}
                                onClick={goToNextMonth}
                            />
                        ) : (
                            <div className={cn('w-12')} />
                        )}
                    </div>
                    <div
                        className={cn('mt-12 w-full flex flex-col items-stretch gap-2')}
                    >
                        <div
                            className={cn(
                                'w-full flex items-center gap-2 justify-between',
                            )}
                        >
                            {dayOfWeekOptions.map((day) => (
                                <div
                                    key={day}
                                    className={cn(
                                        'text-12 font-semibold w-14 text-center',
                                    )}
                                >
                                    {format(getDayFromDateMap(day), 'eee')}
                                </div>
                            ))}
                        </div>
                        {[...Array(6)].map((_, i) => (
                            <CalendarWeek
                                key={i}
                                week={monthDates[i] ?? getEmptyWeek()}
                                month={month}
                                year={year}
                                availableDays={availableDays}
                                onDaySelect={onDaySelect}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default SelectDateContent;
